import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import AppLayout from './layouts/app';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import Page404 from './pages/Page404';
import ModelManagerPage from './pages/ModelManagerPage';
import ModelCatalogPage from 'pages/ModelCatalogPage';
import DBSettingPage from 'pages/DBSettingPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/app',
      element: <AppLayout />,
      children: [
        { element: <Navigate to="/app/modelmanager" />, index: true },
        { path: 'modelmanager', element: <ModelManagerPage /> },
        { path: 'modelcatalog', element: <ModelCatalogPage /> },
        { path: 'dbsetting', element: <DBSettingPage /> },
      ],
    },
    {
      path: '/logout',
      element: <LogoutPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/app/modelmanager" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/app/modelmanager" replace />,
    },
  ]);

  return routes;
}

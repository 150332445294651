import { useEffect } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  Button,
  ButtonGroup,
  Grid,
  Stack,
} from '@mui/material';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { PopupAlert } from 'components/alert';
import * as dfd from "danfojs";
import { useRecoilState } from 'recoil';
import {
  columnRowsState,
  tableRowsState,
  errorMsgState,
  tsCodeState,
} from 'atom';
import { ddlCodeState } from 'atom';
import { useCrtDDL } from 'hooks/backend/useCrtDDL';
import { useExecDDL } from 'hooks/backend/useExecDDL';

export default function DDLNTS({isEdit}) {
  const [ddlCode, setDdlCode] = useRecoilState(ddlCodeState);
  const [columnRows, setColumnRows] = useRecoilState(columnRowsState);
  const [tableRows, setTableRows] = useRecoilState(tableRowsState);
  const [errorMsg, setErrorMsg] = useRecoilState(errorMsgState);
  const [tsCode, setTsCode] = useRecoilState(tsCodeState);

  useEffect(()=>{
    setDdlCode('');
    setTsCode('');
  }, []);

  const getApiParamsData = () => {
    let data = new dfd.DataFrame(columnRows);
    data = dfd.toJSON(data, {format: "row"});
    console.log(data);
    
    return data;
  };

  const postDDLQuery = useCrtDDL(getApiParamsData());
  const execDDLQuery = useExecDDL(getApiParamsData());

  const handleCreateDDLClick = () => {
    postDDLQuery.refetch();
  };

  const handleExecuteDDLClick = () => {
    execDDLQuery.refetch();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
          {isEdit &&
          <Grid item xs={2} mb={3}>
            <ButtonGroup size="medium" variant="contained">
              <Tooltip title="Generate DDL statements for table creation">
                <Button onClick={handleCreateDDLClick}>
                  Create DDL
                </Button>
              </Tooltip>
              <Tooltip title="DB table will be created in the RDBMS as you defined in this UI">
                <Button onClick={handleExecuteDDLClick} color='error'>
                  Execute DDL
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
          }
        <Grid item xs={3} mb={3}>
          <PopupAlert
            msg={errorMsg}
          />
          <Typography variant="h5">
            DDL Info / TS(JS) model
          </Typography>
        </Grid>
        <Grid item xs={9} mb={3}>
        </Grid>
      </Stack>
      <SyntaxHighlighter language="sql">
        {ddlCode}
      </SyntaxHighlighter>
      <SyntaxHighlighter language="typescript">
        {tsCode}
      </SyntaxHighlighter>
    </Box>
  )
}








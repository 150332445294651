import { useQuery } from "react-query";

import { NocoApi } from 'apis/NocoApi';
import { TableApi } from 'apis/TableApi';

import { useRecoilState } from 'recoil';
import { databaseListState } from 'atom';
import { databaseState } from 'atom';
import { errorMsgState } from 'atom';
import { createDBRow } from "models/DBInfoModel";


export function useGetDbInfo(){
    const [databaseList, setDatabaseList] = useRecoilState(databaseListState);
    const [database, setDatabase] = useRecoilState(databaseState);
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const getDbInfoQuery = useQuery("GetDbInfo", () => TableApi.GetDbInfo(), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        onSuccess: (res) => {
            console.log("useGetDbInfo", res);
            const dbInfo = [];
            res.data.map((row, id) => {
                dbInfo.push(createDBRow(id, row.alias, row.url));
            });
            setDatabaseList(dbInfo);
            console.log("dbInfo", dbInfo);
            if(dbInfo.length > 0){
                setDatabase(dbInfo[0]);
                console.log("database", database);
            };
        },
        onError: (error) => {
            console.error("useGetDbInfo", error);
            setErrorMsg(error.message);
            if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
                setErrorMsg(error?.response?.data);
            }
            else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
                setErrorMsg(error?.response?.data?.detail);
            }
            else { //박진우수정
                let errorMsg = "An uncontrolled error occurred.";
                if (error?.response?.data) {
                    errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
                }
                setErrorMsg(errorMsg);
            }
        }
    });
    return getDbInfoQuery;
  }
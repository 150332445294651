import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { useRecoilState } from 'recoil';
import { errorMsgState } from 'atom';
import { databaseState } from 'atom';
import { BackEndApi } from 'apis/BackEndApi';
import { usePostDbInfo } from 'hooks/databases/usePostDbInfo';
import databaseList from 'mock/databaseList';
import fs from 'fs';

export default function DBInput() {
    const [dbName, setDbName] = useState('');
    const [dbUrl, setDbUrl] = useState('');
    const [database, setDatabase] = useRecoilState(databaseState);
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const postDbInfoQuery = usePostDbInfo();

    const handleChangeDbName = (e) => {
        setDbName(e.target.value);
    };
  
    const handleChangeDbUrl = (e) => {
        setDbUrl(e.target.value);
    };

    const handleClick = (e) => {
        console.log(dbName, dbUrl);
        postDbInfoQuery.mutate({dbName, dbUrl});
    };

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            >
                <TextField id="dbName" label="Database Name" value={dbName} onChange={handleChangeDbName} variant="standard" />
                <TextField id="dbUrl" label="Database URL" value={dbUrl} onChange={handleChangeDbUrl} variant="standard" />

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                Save
            </LoadingButton>
        </Box>
    );
}

// export const BackEndApi = {
//     RecmdSchema : (params) => axios.post(process.env.REACT_APP_BACK_END_URL+'/RecmdSchema', params),
//     CrtDDL : (params) => axios.post(process.env.REACT_APP_BACK_END_URL+'/CrtDDL', params),
//     InitMeta : (params) => axios.post(process.env.REACT_APP_BACK_END_URL+'/InitMeta', params),
//     ExecDDL : (params) => axios.post(process.env.REACT_APP_BACK_END_URL+'/ExecDDL', params),
//     ListTable : (params) => axios.post(process.env.REACT_APP_BACK_END_URL+'/ListTable', params),
//     DeleteTbl : (params, tableName) => axios.post(process.env.REACT_APP_BACK_END_URL+'/DeleteTbl?tbl_nm='+tableName, params),
//     SelectDDL : (params, tableName) => axios.post(process.env.REACT_APP_BACK_END_URL+'/SelectDDL?data='+tableName, params),
// }

import { backEndInstance } from "./Instance";

export const BackEndApi = {
    RecmdSchema : (params) => backEndInstance.post('RecmdSchema', params),
    ModifyRecmdSchema : (params) => backEndInstance.post('modify/RecmdSchema', params),
    CrtDDL : (params) => backEndInstance.post('CrtDDL', params),
    InitMeta : (params) => backEndInstance.post('InitMeta', params),
    ExecDDL : (params) => backEndInstance.post('ExecDDL', params),
    ListTable : (params) => backEndInstance.post('ListTable', params),
    DeleteTbl : (params, tableName) => backEndInstance.post('DeleteTbl?tbl_nm='+tableName, params),
    SelectDDL : (params, tableName) => backEndInstance.post('SelectDDL?data='+tableName, params),
    ModifyEdit : (params) => backEndInstance.post('modify/edit', params),
}
import { useQuery } from "react-query";

import { BackEndApi } from "apis/BackEndApi";

import { useRecoilState } from 'recoil';
import {
    databaseState,
    errorMsgState,
    ddlCodeState,
    tsCodeState,
} from 'atom';

export function useCrtDDL(data){
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const [ddlCode, setDdlCode] = useRecoilState(ddlCodeState);
    const [tsCode, setTsCode] = useRecoilState(tsCodeState);
    const [database, setDatabase] = useRecoilState(databaseState);

    let params = {
        data : data,
        db_url : {
            db_url : database ? database.db_url : null,
        }
    }

    const postDDLQuery = useQuery('postDDL', () => BackEndApi.CrtDDL(params), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        onSuccess: (res) => {
            console.log(res);
            let data = res.data;
            setDdlCode(data.table_ddl);
            setTsCode(data.ts_interface);
        },
        onError: (error) => {
            console.error(error);
            setErrorMsg(error.message);
            if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
                setErrorMsg(error?.response?.data);
            }
            else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
                setErrorMsg(error?.response?.data?.detail);
            }
            else { //박진우수정
                let errorMsg = "An uncontrolled error occurred.";
                if (error?.response?.data) {
                    errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
                }
                setErrorMsg(errorMsg);
            }
        }
    });
    return postDDLQuery;
  }
export const createColumnRow = (
    id=0,
    col_cmnt_ko='', 
    col_nm='', 
    col_typ_select='', 
    col_typ='', 
    col_lngth=-1, 
    col_dflt_vlue='',
    col_nullable=false,
    col_autncrmnt=false,
    pk=false,
    fk='',
    uk='',
    ck='',
    col_cmnt_en='',
  ) => {
    return { 
      id: id,
      col_cmnt_ko: col_cmnt_ko, 
      col_nm: col_nm,  
      col_typ_select: col_typ_select,
      col_typ: col_typ, 
      col_lngth: col_lngth, 
      col_dflt_vlue: col_dflt_vlue,
      col_nullable: col_nullable,
      col_autncrmnt: col_autncrmnt,
      pk: pk,
      fk: fk,
      uk: uk,
      ck: ck,
      col_cmnt_en: col_cmnt_en,
    };
  };
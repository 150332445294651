import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LogoutPage() {
  console.log('logout')
  const navigate = useNavigate();
  useEffect(() => {
  
    sessionStorage.removeItem('user');
    
    navigate('/');
    window.location.reload(false);
  }, [navigate])
  return
}

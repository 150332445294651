
import { useEffect, useState } from 'react';
import React from 'react';
// @mui
import {
    Box,Grid,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    ButtonGroup,
} from '@mui/material';

import { useRecoilState } from 'recoil';
import {
    tableListState,
    databaseState,
} from 'atom';
import { useGetListTable } from 'hooks/backend/useGetListTable';
import { useGetDDL } from 'hooks/backend/useGetDDL';
import { useDeleteTable } from 'hooks/backend/useDeleteTable';
import { useModifyEdit } from 'hooks/backend/useModifyEdit';
import { tableNameState } from 'atom';
import { PopupConfirm } from 'components/dialog'
import { Autocomplete, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export function ModelCatalogTableList({isEdit, setIsEdit, setTableName: setTableNameFromParent}){
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [tableName, setTableName] = useRecoilState(tableNameState);
    const [tableList, setTableList] = useRecoilState(tableListState);
    const [database, setDatabase] = useRecoilState(databaseState);

    const [inputValue, setInputValue] = useState('');
    const [selectedTableName, setSelectedTableName] = useState('');


    const getListTableQuery = useGetListTable();
    const getDDLQuery = useGetDDL();
    const deleteTableQuery = useDeleteTable();
    const execModifyQuery = useModifyEdit();


    useEffect(()=>{
        getListTableQuery.refetch();
        console.log('------------------ 1')
        // getDDLQuery.refetch();
    }, []);

    useEffect(()=>{
        console.log('------------------ 2',tableName)
        if (tableName && tableName.trim() !== "") {  // tableName이 null이 아니고 빈 문자열이 아닐 때만 요청을 보냅니다.
            getDDLQuery.refetch();
        }
    }, [tableName])

    const handleClickTableDelete = (e) => {
        setOpenDelete(true);
    };
    const handleClickTableEdit = (e) => {
        setOpenEdit(true);
    };
    const handleClickTableDeleteFunc = (e) => {
        deleteTableQuery.mutate(tableName, {
            onSuccess: () => {
                // 테이블 삭제가 성공적으로 이루어진 후, 테이블 목록을 다시 불러옵니다.
                getListTableQuery.refetch();
            }
        });
        setOpenDelete(false);
        // window.location.reload();
    };
    const handleClickTableEditFunc = (e) => {
        execModifyQuery.refetch();
        setOpenEdit(false);
        // window.location.reload();
    };

    const formatSelectedValue = (selectedValue) => {
        if (typeof selectedValue === 'string') {
            const parts = selectedValue.split(" ┊ ");
            if (parts.length === 3) {
                // 여기서는 단순한 문자열만 반환합니다. 스타일링이 필요하지 않습니다.
                return `${parts[0]} ┊ ${parts[1]} ┊ ${parts[2]}`;
            }
        } else if (selectedValue && typeof selectedValue === 'object') {
            return `${selectedValue.tbl_nm} ┊ ${selectedValue.tbl_cmnt_en} ┊ ${selectedValue.tbl_cmnt_ko}`;
        }
        return selectedValue || '';
    };

    // const formatSelectedValue = (selectedValue) => {
    //     console.log('********************************', typeof selectedValue, selectedValue)

    //     if (selectedValue && typeof selectedValue === 'object') {
    //         console.log('=========================================================')
    //         return (
    //             <>
    //                 <span style={{fontWeight: '700'}}>{selectedValue.tbl_nm}</span>
    //                 {' ┊ '}
    //                 <span style={{color: '#007BFF'}}>{selectedValue.tbl_cmnt_en}</span>
    //                 {' ┊ '}
    //                 <span style={{color: '#FF5733'}}>{selectedValue.tbl_cmnt_ko}</span>
    //             </>
    //         );
    //     } else if (typeof selectedValue === 'string') {
    //         const [tbl_nm, tbl_cmnt_en, tbl_cmnt_ko] = selectedValue.split(' ┊ ');
    //         console.log('=========',tbl_nm, tbl_cmnt_en, tbl_cmnt_ko,'=========')
    //         return (
    //             <>
    //                 <span style={{fontWeight: '700'}}>{tbl_nm}</span>
    //                 {' ┊ '}
    //                 <span style={{color: '#007BFF'}}>{tbl_cmnt_en}</span>
    //                 {' ┊ '}
    //                 <span style={{color: '#FF5733'}}>{tbl_cmnt_ko}</span>
    //             </>
    //         );
    //     }

    //     return selectedValue || '';
    // };
    // const StyledInput = React.forwardRef((props, ref) => {
    //     const { value, ...otherProps } = props;
    //     const styledValue = formatSelectedValue(value);

    //     return <div ref={ref} {...otherProps} focus={() => {}}>{styledValue}</div>;
    // });

    return  (
        <Box sx={{ width: '100%' }}>
            <PopupConfirm
                open={openDelete}
                setOpen={setOpenDelete}
                msg="Do you want to delete all contents related to the selected table?"
                func={handleClickTableDeleteFunc}
            />
            <PopupConfirm
                open={openEdit}
                setOpen={setOpenEdit}
                msg="Should I apply the modified content to the server?"
                func={handleClickTableEditFunc}
            />

            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={9}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={selectedTableName}
                            inputValue={inputValue}
                            onChange={(event, newValue) => {
                                console.log("Autocomplete onChange triggered", newValue);
                                setSelectedTableName(newValue);
                                if (newValue && newValue.tbl_nm) {
                                    setTableName(newValue.tbl_nm);
                                    setTableNameFromParent(newValue.tbl_nm); // 상위 컴포넌트에게 테이블 이름 전달
                                } else {
                                    setTableName('');
                                    setTableNameFromParent(''); // 상위 컴포넌트에게 빈 문자열 전달
                                }
                                if (newValue && newValue.tbl_nm && newValue.tbl_cmnt_en && newValue.tbl_cmnt_ko) {
                                    setInputValue(`${newValue.tbl_nm} ┊ ${newValue.tbl_cmnt_en} ┊ ${newValue.tbl_cmnt_ko}`);
                                } else {
                                    setInputValue('');
                                }
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            options={tableList.map((table) => ({ tbl_nm: table.tbl_nm, tbl_cmnt_en: table.tbl_cmnt_en, tbl_cmnt_ko: table.tbl_cmnt_ko }))}
                            getOptionLabel={(option) => {
                                if (option.tbl_nm) {
                                    return `${option.tbl_nm} ┊ ${option.tbl_cmnt_en} ┊ ${option.tbl_cmnt_ko}`;
                                }
                                return option;
                            }}

                            renderInput={(params) => {
                                const { InputProps, inputProps, ...other } = params;
                                const { value, ...otherInputProps } = inputProps;

                                return (
                                    <TextField
                                        {...other}
                                        InputProps={{
                                            ...InputProps,
                                            inputProps: {
                                                ...otherInputProps,
                                                value: formatSelectedValue(value),
                                            },
                                        }}
                                        label="Please Select a Table to modify in this list"
                                        variant="outlined"
                                    />
                                );
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <div {...props}>
                                        <span style={{ fontWeight: 700 }}>{option.tbl_nm}</span> ┊
                                        <span style={{ color: '#007BFF' }}>{option.tbl_cmnt_en}</span> ┊
                                        <span style={{ color: '#FF5733' }}>{option.tbl_cmnt_ko}</span>
                                    </div>
                                );
                            }}
                            freeSolo
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <ButtonGroup size="medium" variant="contained">
                        <Button onClick={handleClickTableEdit}>
                            Apply Edit
                        </Button>
                        <Button onClick={handleClickTableDelete} color='error'>
                            Delete
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Box>
    )
}
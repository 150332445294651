import { useState } from 'react';

// @mui
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  Stack,
  Box,
  Typography,
} from '@mui/material';

import { createColumnRow } from 'models/ModelManagerColumnModel';
import { createTableRow } from 'models/ModelManagerTableModel';
import { useRecoilState } from 'recoil';
import {
  columnRowsState,
  tableRowsState,
} from 'atom';

export function ModelManagerInputData(){
    const [columnRows, setColumnRows] = useRecoilState(columnRowsState);
    const [tableRows, setTableRows] = useRecoilState(tableRowsState);

    const [sep, setSep] = useState('\t');
    const [inputData, setInputData] = useState('');

    const handleClickConvert = (e) => {
      let list = inputData.split(sep)
      setTableRows([createTableRow(list[0],'','')])
      let columns = list.splice(1,)
      setColumnRows([])
      columns.map((column, idx) => {
        setColumnRows(columnRows=>[...columnRows, createColumnRow(idx, column,'','','VARCHAR',-1,'',false,false,false,'','','','','')]);
      })
    };

    const handleChangeSep = (e) => {
      setSep(e.target.value);
    };

    const handleChangeInputData = (e) => {
       const filtered_str =e.target.value.replace("Field Name (Kr)\t","").replace("Field Name (En)\t", "")
       console.log(filtered_str)
      setInputData(filtered_str);
    //   setInputData(e.target.value);
    };

    const sepList = [
        {name: 'Tab', value: "\t"},
        {name: 'Space', value: " "},
        {name: 'Comma', value: ","},
        {name: 'Pipe', value: "|"},
        {name: 'Semicolon', value: ";"},
    ];

    return (
        <Box sx={{ width: '100%' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={1} mr={1} ml={1} >
                    <Tooltip title="convert string to table format in the table below">
                        <Button size="medium" variant="contained" onClick={handleClickConvert}> Convert </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={1} mr={1} ml={1} >
                    <Select
                        style={{ height: "40px" }}
                        value={sep}
                        label="Seperate"
                        onChange={handleChangeSep}
                        defaultValue='\t'
                    >
                        {
                            sepList.map(sepData => {
                                return (
                                    <MenuItem key={sepData.value} value={sepData.value}>
                                        [{sepData.value}] {sepData.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Grid>
                <Grid item xs={5} >
                    <Typography variant="h5">
                        Korean Table/Field Names
                    </Typography>
                </Grid>
                <Grid item xs={8} mr={2} >
                </Grid>
            </Stack>
            <Grid item xs={14} sm={6} md={12}>
                <FormControl fullWidth>
                    <TextField
                        label="Input Column List"
                        multiline
                        fullWidth
                        rows={6}
                        value={inputData}
                        onChange={handleChangeInputData}
                    />
                </FormControl>
            </Grid>
        </Box>
    )
}
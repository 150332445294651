import { useQuery } from "react-query";

import { BackEndApi } from "apis/BackEndApi";

import { useRecoilState } from 'recoil';
import {
    errorMsgState,
    sussMsgState,
    databaseState,
    ddlCodeState,
} from 'atom';

export function useExecDDL(data){
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const [sussmsg, setSussMsg] = useRecoilState(sussMsgState);
    const [database, setDatabase] = useRecoilState(databaseState);
    const [ddlCode, setDdlCode] = useRecoilState(ddlCodeState);

    let params = {
      query : {
        query : ddlCode,
      },
      data : data,
      db_url : {
          db_url : database ? database.db_url : null,
      }
    }

    const execDDLQuery = useQuery('execDDL', () => BackEndApi.ExecDDL(params), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        onSuccess: (res) => {
            console.log(res);
            setSussMsg("Success Execute DDL");
        },
        onError: (error) => {
            console.error(error);
            setErrorMsg(error.message);
            if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
              setErrorMsg(error?.response?.data);
            }
            else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
              setErrorMsg(error?.response?.data?.detail);
            }
            else { //박진우수정
              let errorMsg = "An uncontrolled error occurred.";
              if (error?.response?.data) {
                  errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
              }
              setErrorMsg(errorMsg);
            }
        }
    });
    return execDDLQuery;
  }
import { useQuery } from "react-query";

import { useRecoilState } from 'recoil';
import {
  columnRowsState,
  tableRowsState,
  tableListState,
  errorMsgState,
  ddlCodeState,
  tsCodeState,
  tableNameState,
  databaseState,
} from 'atom';
import { BackEndApi } from "apis/BackEndApi";


export function useGetListTable(){
    const [columnRows, setColumnRows] = useRecoilState(columnRowsState);
    const [tableRows, setTableRows] = useRecoilState(tableRowsState);
    const [ddlCode, setDdlCode] = useRecoilState(ddlCodeState);
    const [tsCode, setTsCode] = useRecoilState(tsCodeState);
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const [tableList, setTableList] = useRecoilState(tableListState);
    const [tableName, setTableName] = useRecoilState(tableNameState);
    const [database, setDatabase] = useRecoilState(databaseState);

    const params = {
        db_url : database ? database.db_url : null
    };
    const getListTableQuery = useQuery("GetListTable", () => BackEndApi.ListTable(params), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        // onSuccess: (res) => {
        //     console.log(res);
        //     setTableList(JSON.parse(res.data.data).tbl_nm);
        //     console.log(tableList);
        //     setTableName(tableList[0]);
        //     setColumnRows([]);
        //     setTableRows([]);
        //     setDdlCode('');
        //     setTsCode('');
        // },
        onSuccess: (res) => {
            console.log(res);
            setTableList(res.data.data.tables);
            console.log(tableList);
            // setTableName(tableList[0].tbl_nm);
            setTableName('');
            setColumnRows([]);
            setTableRows([]);
            setDdlCode('');
            setTsCode('');
        },

        onError: (error) => {
            console.error(error);
            setErrorMsg(error.message);
            if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
                setErrorMsg(error?.response?.data);
            }
            else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
                setErrorMsg(error?.response?.data?.detail);
            }
            else { //박진우수정
                let errorMsg = "An uncontrolled error occurred.";
                if (error?.response?.data) {
                    errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
                }
                setErrorMsg(errorMsg);
            }
        }
    });
    return getListTableQuery;
  }
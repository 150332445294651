import { useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import {
  Grid,
  Container,
  Typography,
} from '@mui/material';

// sections
import {
  DataGridTableData,
  DataGridColumnData,
  DDLNTS,
} from 'sections/@app/ModelManager';
import { ModelCatalogTableList } from 'sections/@app/ModelCatalog/ModelCatalogTableList';
// import Typography from 'theme/overrides/Typography';

export default function ModelCatalogPage() {
  const [isEdit, setIsEdit] = useState(true);
  const [tableName, setTableName] = useState(null); // 추가: 선택된 테이블 이름을 상태로 관리

  return (
    <>
      {/* <Helmet>
        <title> DBSetting | ModelManager </title>
      </Helmet> */}

      <Container maxWidth="100%">
        <Typography variant="h4" sx={{ mb: 5 }}>Table DDL check / Delete / Modify (TBD)</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <ModelCatalogTableList
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setTableName={setTableName}  // 추가: setTableName을 prop으로 전달
            />
          </Grid>
          {tableName && (
            <>
          <Grid item xs={12} sm={12} md={12}>
            <DataGridTableData isEdit={isEdit} isModify={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <DataGridColumnData isEdit={isEdit} isModify={true}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <DDLNTS isEdit={false} />
          </Grid>
          </>
          )}
        </Grid>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <ModelCatalogTableList/>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <DataGridTableData isEdit={false} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <DataGridColumnData isEdit={false} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <DDLNTS isEdit={false} />
        </Stack> */}
      </Container>
    </>
  );
}

import { useState } from "react";
import { Dialog, DialogActions, DialogTitle, Button } from "@mui/material";

export default function PopupConfirm({open, setOpen, msg, func}) {  
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = () => {
        func();
    };

    const popupConfirm = (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {msg}
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>NO</Button>
                <Button onClick={handleConfirm} autoFocus>
                    YES
                </Button>
            </DialogActions>


        </Dialog>
    );

    return popupConfirm
}
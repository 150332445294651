import { useQuery } from "react-query";

import { useRecoilState } from 'recoil';
import {
  columnRowsState,
  tableRowsState,
  errorMsgState,
  ddlCodeState,
  tsCodeState,
  tableNameState,
  databaseState,
} from 'atom';
import { BackEndApi } from "apis/BackEndApi";
import { createTableRow } from "models/ModelManagerTableModel";
import * as dfd from "danfojs"


export function useGetDDL(){
    const [columnRows, setColumnRows] = useRecoilState(columnRowsState);
    const [tableRows, setTableRows] = useRecoilState(tableRowsState);
    const [ddlCode, setDdlCode] = useRecoilState(ddlCodeState);
    const [tsCode, setTsCode] = useRecoilState(tsCodeState);
    const [tableName, setTableName] = useRecoilState(tableNameState);
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const [database, setDatabase] = useRecoilState(databaseState);

    const params = {
        db_url : database ? database.db_url : null,
    };

    const getDDLQuery = useQuery("GetDDL", () => BackEndApi.SelectDDL(params, tableName), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        onSuccess: (res) => {
            console.log(res);
            let data = JSON.parse(res.data.data);
            setTableRows([createTableRow(data.tbl_cmnt_ko[0], data.tbl_nm[0], data.tbl_cmnt_ko[0] ,data.tbl_cmnt_en[0])]);
            delete data.tbl_nm;
            delete data.tbl_cmnt_en;
            delete data.tbl_cmnt_ko;
            setDdlCode(res.data.table_ddl);
            setTsCode(res.data.ts_interface);
            console.log(ddlCode);
            console.log(data);
            let dfColumn = new dfd.DataFrame(data);
            dfColumn.addColumn("id", dfColumn.index, { inplace: true });
            let jsonColumn = dfd.toJSON(dfColumn);
            setColumnRows(jsonColumn);
        },
        onError: (error) => {
            console.error(error);
            setErrorMsg(error.message);
            if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
                setErrorMsg(error?.response?.data);
            }
            else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
                setErrorMsg(error?.response?.data?.detail);
            }
            else { //박진우수정
                let errorMsg = "An uncontrolled error occurred.";
                if (error?.response?.data) {
                    errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
                }
                setErrorMsg(errorMsg);
            }
        }
    });
    return getDDLQuery;
  }
import { Helmet } from 'react-helmet-async';

// @mui
import {
  Grid,
  Container,
  Typography,
} from '@mui/material';

// sections
import {
  DataGridTableData,
  DataGridColumnData,
  DDLNTS,
} from '../sections/@app/ModelManager';
import { ModelManagerInputData } from 'sections/@app/ModelManager/ModelManagerInputData';

// ----------------------------------------------------------------------

export default function ModelManagerPage() {
  return (
    <>
      {/* <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet> */}

      <Container maxWidth="100%">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Model Creation
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <ModelManagerInputData />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <DataGridTableData isEdit={true} isModify={false}/>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <DataGridColumnData isEdit={true} isModify={false}/>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <DDLNTS isEdit={true} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}


import { useQuery } from "react-query";

import { BackEndApi } from "apis/BackEndApi";

import { useRecoilState } from 'recoil';
import * as dfd from "danfojs";
import {
    errorMsgState,
    sussMsgState,
    databaseState,
    columnRowsState,
    tableRowsState,
} from 'atom';

export function useModifyEdit(){
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const [sussmsg, setSussMsg] = useRecoilState(sussMsgState);
    const [database, setDatabase] = useRecoilState(databaseState);
    const [columnRows, setColumnRows] = useRecoilState(columnRowsState);
    const [tableRows, setTableRows] = useRecoilState(tableRowsState);

    const getApiParamsData = () => {
      let data = new dfd.DataFrame(columnRows);
      let tbl_nm = [];
      for (let i=0; i<columnRows.length; i++){
        tbl_nm.push(tableRows[0]?.tbl_nm_en);
      }
      let tbl_cmnt_ko = [];
      for (let i=0; i<columnRows.length; i++){
        tbl_cmnt_ko.push(tableRows[0]?.tbl_cmnt_ko);
      }
      let tbl_cmnt_en = [];
      for (let i=0; i<columnRows.length; i++){
        tbl_cmnt_en.push(tableRows[0]?.tbl_cmnt_en);
      }
      data.addColumn("tbl_nm", tbl_nm, { inplace: true });
      data.addColumn("tbl_cmnt_ko", tbl_cmnt_ko, { inplace: true });
      data.addColumn("tbl_cmnt_en", tbl_cmnt_en, { inplace: true });
      data = dfd.toJSON(data, {format: "row"});
      console.log(data);
      return data;
    };

    let params = {
      data : getApiParamsData(),
      db_url : {
          db_url : database ? database.db_url : null,
      }
    }

    const execModifyQuery = useQuery('modifyEdit', () => BackEndApi.ModifyEdit(params), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        onSuccess: (res) => {
          console.log(params);
          console.log(res);
          setSussMsg("Success Execute Edit");
        },
        onError: (error) => {
          console.log(params);
          console.error(error);
          setErrorMsg(error.message);
          if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
            setErrorMsg(error?.response?.data);
          }
          else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
            setErrorMsg(error?.response?.data?.detail);
          }
          else { //박진우수정
            let errorMsg = "An uncontrolled error occurred.";
            if (error?.response?.data) {
                errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
            }
            setErrorMsg(errorMsg);
          }
        }
    });
    return execModifyQuery;
  }
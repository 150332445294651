import { useMutation, useQueryClient } from "react-query";

import { NocoApi } from 'apis/NocoApi';
import { TableApi } from 'apis/TableApi';

import { useRecoilState } from 'recoil';
import { errorMsgState } from 'atom';


export function usePostDbInfo(){
    const queryClient = useQueryClient();
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const postDbInfoQuery = useMutation(({dbName, dbUrl}) => TableApi.PostDbInfo(dbName, dbUrl), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        onSuccess: (res) => {
            console.log('usePostDbInfo', res);
            queryClient.invalidateQueries("GetDbInfo");
            queryClient.invalidateQueries("InitMeta");
        },
        onError: (error) => {
            console.error('usePostDbInfo', error);
            setErrorMsg(error.message);
            if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
                setErrorMsg(error?.response?.data);
            }
            else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
                setErrorMsg(error?.response?.data?.detail);
            }
            else { //박진우수정
                let errorMsg = "An uncontrolled error occurred.";
                if (error?.response?.data) {
                    errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
                }
                setErrorMsg(errorMsg);
            }
        }
    });
    return postDbInfoQuery;
  }
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
} from '@mui/material';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { DBListHead, DBInput } from '../sections/@app/DBSetting';
import { useRecoilState } from 'recoil';
import { databaseListState } from 'atom';
import { useDeleteDbInfo } from 'hooks/databases/useDeleteDbInfo';
import { useGetTest } from 'hooks/useGetTest';
import { useTheme } from '@mui/material/styles';


// import databaseList from 'mock/databaseList';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'dbName', label: 'Database Name', alignRight: false },
  { id: 'dbURL', label: 'Database URL', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


export default function DBSettingPage() {
  // const getTestQuery = useGetTest();
  const theme = useTheme();
  const [databaseList, setDatabaseList] = useRecoilState(databaseListState);
  const deleteDbInfoQuery = useDeleteDbInfo();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [openMenuId, setOpenMenuId] = useState();
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event, id) => {
    setOpenMenu(event.currentTarget);
    console.log(id);
    setOpenMenuId(id);
  };
  const handleCloseMenu = () => setOpenMenu(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = databaseList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleClickDelete = (event) => {
    console.log(openMenuId);
    deleteDbInfoQuery.mutate(openMenuId);
    setOpenMenu(false);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - databaseList.length) : 0;
  const isNotFound = !databaseList.length && !!filterName;


  return (
    <>
      {/* <Helmet>
        <title> DBSetting | ModelManager </title>
      </Helmet> */}

      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            DB Setting
          </Typography>
          <Button size="menium" variant="contained" onClick={handleOpenModal} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Database
          </Button>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <DBInput></DBInput>
            </Box>
          </Modal>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <DBListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={databaseList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {databaseList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, id) => {
                    const { db_name, db_url } = row;
                    const selectedDbInfo = selected.indexOf(db_name) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1}>

                        <TableCell align="left">{db_name}</TableCell>

                        <TableCell align="left">{db_url}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event)=>handleOpenMenu(event,db_name)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={databaseList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              ...theme.typography.body2,  // 이 부분을 추가
              px: 1,
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }}
          onClick={(event)=>handleClickDelete(event)}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}

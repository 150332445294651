// component
import SvgColor from '../../../components/svg-color';
import SettingsIcon from '@mui/icons-material/Settings';
import TransformIcon from '@mui/icons-material/Transform';
import LogoutIcon from '@mui/icons-material/Logout';
import CategoryIcon from '@mui/icons-material/Category';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'; // Create
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded'; // Modify

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Create',
    path: '/app/modelmanager',
    icon: <AddBoxRoundedIcon />,
  },
  {
    title: 'Modify',
    path: '/app/modelcatalog',
    icon: <EditNoteRoundedIcon />,
  },
  {
    title: 'DB Setting',
    path: '/app/dbsetting',
    icon: <SettingsIcon />,
  },
  // {
  //   title: 'product',
  //   path: '/app/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/app/blog',
  //   icon: icon('ic_blog'),
  // },
  {
    title: 'logout',
    path: '/logout',
    icon: <LogoutIcon />,
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GridActionsCellItem,
  DataGrid,
  GridRowEditStopReasons,
  useGridApiRef,
} from '@mui/x-data-grid';


import {
  Box,
  Typography,
  Tooltip,
  Button,
  LinearProgress,
  Stack,
  Grid,
} from '@mui/material';
import { green } from '@mui/material/colors';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { createColumnRow } from 'models/ModelManagerColumnModel';
import { createTableRow } from 'models/ModelManagerTableModel';
import { useRecoilState } from 'recoil';
import {
  columnRowsState,
  tableRowsState,
  errorMsgState,
} from 'atom';
import * as dfd from "danfojs"

import { BackEndApi } from 'apis/BackEndApi';
import { ParameterSelect } from 'components/data-table/select';
import { useRecmdSchema } from 'hooks/backend/useRecmdSchema';
import { idID } from '@mui/material/locale';


const useFakeMutation = () => {
  return useCallback(
    (newRow, columnRows, setColumnRows, mode) =>
      new Promise((resolve, reject) => {
        console.log('columnRows',columnRows);
        let updatedRows = {...columnRows}
        if (mode == 'update'){
          updatedRows = columnRows.map((row) => {
            if (row.id === newRow.id) {
              return newRow;
            }
            return row;
          });
        } else if (mode == 'delete'){
          updatedRows = columnRows.filter((row) => row.id !== newRow.id);
          updatedRows = updatedRows.map((row, i) => {
            return {...row, id: i}
          });
        }
        setColumnRows(updatedRows);
        console.log(columnRows);
        setTimeout(() => {
          resolve(newRow);
        }, 200);
      }),
    []
  );
};

export default function DataGridColumnData({isEdit, isModify}) {
  const apiRef = useGridApiRef();
  const mutateRow = useFakeMutation();
  // const [columnColumns, setColumnColumns] = useState([
  //   {
  //     field: 'actions',
  //     type: 'actions',
  //     headerName: 'Actions',
  //     width: 100,
  //     cellClassName: 'actions',
  //     getActions: (params) => {
  //       return [
  //         <GridActionsCellItem
  //           icon={<DeleteIcon />}
  //           label="Delete"
  //           onClick={handleDeleteClick(params)}
  //           color="inherit"
  //         />,
  //       ];
  //     },
  //   },
  //   { field: 'id', headerName: 'id', flex: 1, hideable: true, editable: false },
  //   { field: 'col_cmnt_ko', headerName: 'Column Name (Korean)', flex: 4, editable: isEdit },
  //   { field: 'col_nm', headerName: 'Column Name (English)', flex: 4, editable: isEdit },
  //   { field: "col_typ", headerName: 'Column Type', type: 'singleSelect'
  //     , valueOptions: ['VARCHAR','INTEGER','NUMERIC','BIGINT','FLOAT','SERIAL','BIGSERIAL'
  //     ,'DATE','TIME','TIMESTAMP','TIMESTAMPTZ','INTERVAL','BOOL', 'ENUM','SET']
  //     , flex: 4, editable: isEdit },
  //   { field: 'col_lngth', headerName: 'Column Length', flex: 2, type: 'number', editable: isEdit },
  //   { field: 'col_dflt_vlue', headerName: 'Column Default Value', flex: 2, editable: isEdit },
  //   { field: 'col_nullable', headerName: 'Column Nullable', flex: 2, type: 'boolean', editable: isEdit },
  //   { field: 'col_autncrmnt', headerName: 'Column Autncrmnt', flex: 2, type: 'boolean', editable: isEdit },
  //   { field: 'pk', headerName: 'Primary Key', flex: 1, type: 'boolean', editable: isEdit },
  //   { field: 'fk', headerName: 'Foreign Key', flex: 1, editable: isEdit },
  //   { field: 'uk', headerName: 'Unique Key', flex: 1, editable: isEdit },
  //   { field: 'ck', headerName: 'Check Key', flex: 1, editable: isEdit },
  //   { field: 'col_cmnt_en', headerName: 'Column Comment (English)', flex: 4, editable: isEdit }
  // ]);
  const [loading, setLoading] = useState(false);
  const [columnRows, setColumnRows] = useRecoilState(columnRowsState);
  const [tableRows, setTableRows] = useRecoilState(tableRowsState);
  const [errorMsg, setErrorMsg] = useRecoilState(errorMsgState);

  const recmdSchemaQuery = useRecmdSchema(setLoading);

  useEffect(()=>{
    setColumnRows([]);
  }, []);

  const columnColumns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params)}
            color="inherit"
          />,
        ];
      },
    },
    { field: 'id', headerName: 'id', flex: 1, hideable: true, editable: false },
    { field: 'col_cmnt_ko', headerName: 'Column Name (Korean)', flex: 4, editable: isEdit },
    { field: 'col_nm', headerName: 'Column Name (English)', flex: 4, editable: isEdit },
    { field: "col_typ", headerName: 'Column Type', type: 'singleSelect'
      , valueOptions: ['VARCHAR','INTEGER','NUMERIC','BIGINT','FLOAT','SERIAL','BIGSERIAL'
      ,'DATE','TIME','TIMESTAMP','TIMESTAMPTZ','INTERVAL','BOOL', 'ENUM','SET']
      , flex: 3.5, editable: isEdit },
    { field: 'col_lngth', headerName: 'Column Length', flex: 1, type: 'number', editable: isEdit },
    { field: 'col_dflt_vlue', headerName: 'Default Value', flex: 1, editable: isEdit },
    { field: 'col_nullable', headerName: 'Nullable', flex: 1, type: 'boolean', editable: isEdit },
    { field: 'col_autncrmnt', headerName: 'Auto Incrmnt', flex: 1, type: 'boolean', editable: isEdit },
    { field: 'pk', headerName: 'Primary Key', flex: 1, type: 'boolean', editable: isEdit },
    { field: 'fk', headerName: 'Foreign Key', flex: 2, editable: isEdit },
    { field: 'uk', headerName: 'Unique Key', flex: 3, editable: isEdit },
    { field: 'ck', headerName: 'Check Key', flex: 2, editable: isEdit },
    { field: 'col_cmnt_en', headerName: 'Column Comment (English)', flex: 4, editable: isEdit }
  ]
  // useEffect(()=>{
  //   setColumnColumns(columnColumns.map((row) => {
  //     if(!(["id", "col_cmnt_ko"].includes(row.field))){
  //       row = {...row, editable: isEdit}
  //     }
  //     return row
  //   }));
  // }, [isEdit]);

  useEffect(()=>{
    console.log('columnRows', columnRows);
  }, [columnRows])

  const handleDeleteClick = (parmas) => () => {
    console.log("handleDeleteClick : params", parmas);
    let newColumnsRows = columnRows.filter((row) => row.id !== parmas.id);
    console.log("handleDeleteClick : delete", newColumnsRows);
    setColumnRows(newColumnsRows.map((row, i) => {
      return {...row, id: i};
    }));
    console.log("handleDeleteClick : reindex", newColumnsRows);
  };

  // const handleDeleteClick = (params) =>  useCallback(
  //   async (id) => {
  //     console.log(params.row, columnRows, setColumnRows);
  //     const response = await mutateRow(params.row, columnRows, setColumnRows, 'delete');
  //     return response;
  //   },
  //   [mutateRow],
  // );

  const addRow = () => setColumnRows(columnRows=>[...columnRows, createColumnRow(columnRows.length,'','','','',-1,'',false,false,false,'','','','','')]);

  const tranlateRow = () => {
    setLoading(true);
    recmdSchemaQuery.refetch();
  };

  const handleEditApply = () => {
    console.log(columnRows);
  }

  // const processRowUpdate = () => {
  //   console.log(columnRows);
  // };
  const processRowUpdate = useCallback(
    async (newRow, columnRows, setColumnRows) => {
      delete newRow["actions"];
      console.log(newRow, columnRows, setColumnRows);
      const response = await mutateRow(newRow, columnRows, setColumnRows, 'update');
      return response;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log("handleProcessRowUpdateError", error);
  }, []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
          <Tooltip title="append a row to the table below">
            <Button size="small" onClick={addRow}>
              Add Rows
            </Button>
          </Tooltip>
          <DataGrid
            editMode="row"
            rows={columnRows}
            columns={columnColumns}
            getRowId={(row) => row.id}
            processRowUpdate={(newRow)=>processRowUpdate(newRow, columnRows, setColumnRows)}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            columnVisibilityModel={{
              actions: isEdit
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  lineHeight: 1
              }
            }}
          />
        {/* {isEdit &&
          <>
          {loading && (
            <LinearProgress />
          )}
          <DataGrid
            editMode="row"
            rows={columnRows}
            columns={columnColumns}
            processRowUpdate={(newRow)=>processRowUpdate(newRow, columnRows, setColumnRows)}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            // columnVisibilityModel={{
            //   col_typ: false
            // }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  lineHeight: 1
              }
            }}
          />
          </>
        }{!isEdit &&
          <DataGrid
            editMode="row"
            rows={columnRows}
            columns={columnColumns}
            processRowUpdate={(newRow)=>processRowUpdate(newRow, columnRows, setColumnRows)}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            columnVisibilityModel={{
              actions: false
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  lineHeight: 1
              }
            }}
          />
        } */}

      </Box>
    </>
  )
}
import { useState, useCallback, useEffect } from 'react';

import {
  GridActionsCellItem,
  DataGrid,
} from '@mui/x-data-grid';

import {
  Box,
  Stack,
  Button,
  Tooltip,
  Typography,
  Grid,
} from '@mui/material';

import { createTableRow } from 'models/ModelManagerTableModel';
import { useRecmdSchema } from 'hooks/backend/useRecmdSchema';
import { useModifyRecmdSchema } from 'hooks/backend/useModifyRecmdSchema';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useRecoilState } from 'recoil';
import { tableRowsState } from 'atom';

const useFakeMutation = () => {
  return useCallback(
    (newRow, tableRows, setTableRows) =>
      new Promise((resolve, reject) => {
        const updatedRows = tableRows.map((row) => {
          if (row.tbl_cmnt_ko === newRow.tbl_cmnt_ko) {
            return newRow;
          }
          return row;
        });
        setTableRows(updatedRows);

        setTimeout(() => {
          resolve(newRow);
        }, 200);
      }),
    []
  );
};

export default function DataGridTableData({isEdit, isModify}) {
  const mutateRow = useFakeMutation();
  const [tableRows, setTableRows] = useRecoilState(tableRowsState);
  const [tableColumns, setTableColumns] = useState([
    { field: 'tbl_nm', headerName: 'Table Name (Korean)', flex: 1, editable: false },
    { field: 'tbl_nm_en', headerName: 'Table Name (English)', flex: 2, editable: isEdit },
    { field: 'tbl_cmnt_ko', headerName: 'Table Comment (Korean)', flex: 2, editable: isEdit },
    { field: 'tbl_cmnt_en', headerName: 'Table Comment (English)', flex: 3, editable: isEdit },
  ]);
  const [loading, setLoading] = useState(false);
  const recmdSchemaQuery = useRecmdSchema(setLoading);
  const modifyRecmdSchemaQuery = useModifyRecmdSchema(setLoading);

  useEffect(()=>{
    setTableRows([]);
  }, []);

  useEffect(()=>{
    setTableColumns(tableColumns.map((row) => {
      if(!(["tbl_nm"].includes(row.field))){
        row = {...row, editable: isEdit}
      }
      return row
    }));
  }, [isEdit]);

  useEffect(()=>{
    console.log('tableRows', tableRows);
  }, [tableRows])

  const tranlateRow = () => {
    setLoading(true);
    recmdSchemaQuery.refetch();
  };

  const tranlateModifyRow = () => {
    setLoading(true);
    modifyRecmdSchemaQuery.refetch();
  };


  const processRowUpdate = useCallback(
    async (newRow, tableRows, setTableRows) => {
      const response = await mutateRow(newRow, tableRows, setTableRows);
      return response;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log("handleProcessRowUpdateError", error);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Grid item xs={2} mb={3}>
          {!isModify &&
            <Tooltip title="translate ko to en and compose filed names and types">
              <Button
                size="medium"
                variant="contained"
                disabled={loading}
                onClick={tranlateRow}
              >
                Translate
              </Button>
            </Tooltip>
          }{isModify &&
            <Tooltip title="translate ko to en and compose filed names and types">
              <Button
                size="medium"
                variant="contained"
                disabled={loading}
                onClick={tranlateModifyRow}
              >
                Translate
              </Button>
            </Tooltip>
          }
        </Grid>
        <Grid item xs={3} mr={2} mb={3}>
          <Typography variant="h5">
            Table / Column Info
          </Typography>
        </Grid>
        <Grid item xs={9} mb={3}>
        </Grid>
      </Stack>
      <DataGrid
        editMode="row"
        rows={tableRows}
        columns={tableColumns}
        getRowId={(row) => row.tbl_nm}
        processRowUpdate={(newRow)=>processRowUpdate(newRow, tableRows, setTableRows)}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1
          }
        }}
      />
    </Box>
  )
}








import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from 'components/iconify';

import { useLogin } from 'hooks/useLogin';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const loginQuery = useLogin({
    "email": email,
    "password": password, 
  });

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleClick(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const handleClick = (e) => {
    if (email === 'admin'){
      sessionStorage.setItem("user", email);
      sessionStorage.setItem("token", 'token');
      navigate('/');
      window.location.reload(false);
    }
    else {
      loginQuery.refetch();
    }

  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" value={email} onChange={handleChangeEmail} onKeyPress={handleOnKeyPress} label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handleChangePassword}
          onKeyPress={handleOnKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          Login
        </LoadingButton>
      </Stack>
    </>
  );
}

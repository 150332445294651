import { useEffect, useMemo, useState } from "react";
import { Alert, Dialog } from "@mui/material";
import { useRecoilState } from 'recoil';
import { errorMsgState, sussMsgState } from 'atom';

export default function PopupAlert() {
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useRecoilState(errorMsgState);
    const [sussMsg, setSussMsg] = useRecoilState(sussMsgState);

    useEffect(()=>{
        if (errorMsg.length > 0) {
            setOpen(true);
        }
    }, [errorMsg]);

    useEffect(()=>{
        if (sussMsg.length > 0) {
            setOpen(true);
        }
    }, [sussMsg]);
  
    const handleClose = () => {
        setErrorMsg('');
        setSussMsg('');
        setOpen(false);
    };

    let AlertMsg;
    if (errorMsg.length > 0) {
        AlertMsg = <Alert severity="error">{errorMsg}</Alert>;
    } 
    if (sussMsg.length > 0) {
        AlertMsg = <Alert severity="success">{sussMsg}</Alert>;
    }
  

    const popupAlertStyles = (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {AlertMsg}
        </Dialog>
    );

    return popupAlertStyles
}
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//
import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 5;
const APP_BAR_DESKTOP = 5;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 2,
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function AppLayout() {
  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      <CssBaseline />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <DrawerHeader />
        <Outlet />
      </Main>
    </StyledRoot>
  );
}

import { useQuery } from "react-query";
import { useNavigate } from 'react-router-dom';

import { LoginApi } from 'apis/LoginApi';

import { useRecoilState } from 'recoil';
import { errorMsgState } from 'atom';

export function useLogin(payload){
  const navigate = useNavigate();
  const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
  const loginQuery = useQuery("login", () => LoginApi.login(payload), {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 0,
    onSuccess: (res) => {
        console.log("login", res)
        sessionStorage.setItem("user", payload.email);
        sessionStorage.setItem("token", res.data.token);
        navigate('/');
        window.location.reload(false);
    },
    onError: (error) => {
        console.error("login", error);
        setErrorMsg(error.message);
        if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
            setErrorMsg(error?.response?.data);
        }
        else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
            setErrorMsg(error?.response?.data?.detail);
        }
        else { //박진우수정
          let errorMsg = "An uncontrolled error occurred.";
          if (error?.response?.data) {
              errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
          }
          setErrorMsg(errorMsg);
        }
    }
  });
  return loginQuery;
}
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// theme
import ThemeProvider from 'theme';

// routes
import Router from 'routes';

// components
import { StyledChart } from 'components/chart';
import ScrollToTop from 'components/scroll-to-top';
import { PopupAlert } from 'components/alert';
import LoginPage from 'pages/LoginPage';

// ----------------------------------------------------------------------

export default function App() {
  const user = sessionStorage.getItem('user');
  let router;
  if (user) {
    router = <Router />;
  } else {
    router = <LoginPage />;
  }
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <PopupAlert/>
          {router}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

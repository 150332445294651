import { useQuery } from "react-query";

import { BackEndApi } from "apis/BackEndApi";

import { useRecoilState } from 'recoil';
import {
    errorMsgState,
    databaseState,
    tableRowsState,
    columnRowsState,
} from 'atom';
import * as dfd from "danfojs"
import { createTableRow } from "models/ModelManagerTableModel";


export function useRecmdSchema(setLoading){
    const [errormsg, setErrorMsg] = useRecoilState(errorMsgState);
    const [database, setDatabase] = useRecoilState(databaseState);
    const [tableRows, setTableRows] = useRecoilState(tableRowsState);
    const [columnRows, setColumnRows] = useRecoilState(columnRowsState);
    let params = {
        "tbl_desc": tableRows[0]?.tbl_nm,
        "col_desc": columnRows.map((row) => {
          return row.col_cmnt_ko
        }),
        db_url : database ? database.db_url : null,
    };
    const recmdSchemaQuery = useQuery('recmdSchema', () => BackEndApi.RecmdSchema(params), {
        refetchOnWindowFocus: false,
        enabled: false,
        retry: 0,
        onSuccess: (res) => {
            console.log(res);
            let data = res.data;
            setTableRows([createTableRow(tableRows[0].tbl_nm, data.tbl_nm[0], data.tbl_cmnt_ko[0], data.tbl_cmnt_en[0])]);
            let dfColumn = new dfd.DataFrame(data);
            dfColumn.addColumn("id", dfColumn.index, { inplace: true });
            let jsonCOlumn = dfd.toJSON(dfColumn);
            console.log(jsonCOlumn);
            setColumnRows(jsonCOlumn);
            setLoading(false);
        },
        onError: (error) => {
            console.error(error);
            setErrorMsg(error.message);
            if(typeof error?.response?.data === 'string' || error?.response?.data instanceof String){
                setErrorMsg(error?.response?.data);
            }
            else if(typeof error?.response?.data?.detail === 'string' || error?.response?.data?.detail instanceof String){
                setErrorMsg(error?.response?.data?.detail);
            }
            else { //박진우수정
                let errorMsg = "An uncontrolled error occurred.";
                if (error?.response?.data) {
                    errorMsg = error.response.data.message || error.response.data.detail || errorMsg;
                }
                setErrorMsg(errorMsg);
            }
            setLoading(false);
        }
    });
    return recmdSchemaQuery;
  }
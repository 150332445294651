import axios from "axios";

export const backEndInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_END_BASE_URL,
    timeout: 100000000,
    headers: {
        'Token': process.env.REACT_APP_BACK_END_TOKEN,
    }
});

export const tableInstance = axios.create({
    baseURL: process.env.REACT_APP_TABLE_BASE_URL,
    timeout: 100000000
});

export const loginInstance = axios.create({
    baseURL: process.env.REACT_APP_NOCO_LOGIN_BASE_URL,
    timeout: 100000000,
    // headers: {
    //     'Token': process.env.REACT_APP_NOCO_LOGIN_TOKEN,
    // }
});

export const testInstance = axios.create({
    baseURL: 'http://ec2-15-165-42-29.ap-northeast-2.compute.amazonaws.com:7777',
    timeout: 100000000
});


import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Button, Menu, MenuItem } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';


// hooks
import useResponsive from 'hooks/useResponsive';
// components
import Iconify from 'components/iconify';
import Logo from 'components/logo';
import Scrollbar from 'components/scrollbar';
import NavSection from 'components/nav-section';
//
import navConfig from './config';
import { useRecoilState } from 'recoil';
import { databaseListState } from 'atom';
import { databaseState } from 'atom';
import { useGetDbInfo } from 'hooks/databases/useGetDbInfo';
// import databaseList from 'mock/databaseList'
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const [databaseList, setDatabaseList] = useRecoilState(databaseListState);
  const [database, setDatabase] = useRecoilState(databaseState);
  const getDbInfoQuery = useGetDbInfo();
  const theme = useTheme();

  const drawerWidth = 240;

  useEffect(()=>{
    getDbInfoQuery.refetch();
    setDatabase(databaseList[0]);
  }, [])

  const handleOpen = (event) => {
    console.log(databaseList)
    console.log(database)
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClick = (event, database) => {
    setDatabase(database);
    setOpen(null);
  };

  const isDesktop = useResponsive('up', 'lg');
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const handleDrawerOpen = () => {
    setNavOpen(true);
  };

  const handleDrawerClose = () => {
    setNavOpen(false);
  };

  useMemo(() => {
    if (openNav) {
      onCloseNav();
    };
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, flexDirection: 'column' },
      }}
    >

      <Box sx={{ mb: 5, mx: 1 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src="/assets/images/avatars/avatar_default.jpg" alt="photoURL" />

            <Box sx={{ ml: 1 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {sessionStorage.getItem('user')}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        Database:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          { database && database.db_name}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {databaseList.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={option.id === database.id}
            onClick={(event)=>handleClick(event, option)}
            // sx={{ typography: 'body2' }}
            sx={{ ...theme.typography.body2 }}
          >
            {option.db_name}
          </MenuItem>
        ))}
      </Menu>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
    >
    <AppBar position="fixed" open={navOpen}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(navOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          Model Manager
        </Typography>
      </Toolbar>
    </AppBar>
      {isDesktop ? (
        <Drawer
          open={navOpen}
          onClose={handleDrawerClose}
          variant="permanent"
        >
        <DrawerHeader>
          <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', marginRight: 'auto' }}>
            <Logo />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={navOpen}
          onClose={handleDrawerClose}
        >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();

export const columnRowsState = atom({
    key: 'columnRowsState', 
    default: [], 
    effects_UNSTABLE: [persistAtom],
});

export const tableRowsState = atom({
    key: 'tableRowsState', 
    default: [], 
    effects_UNSTABLE: [persistAtom],
});

export const errorMsgState = atom({
    key: 'errorMsgState', 
    default: '', 
    effects_UNSTABLE: [persistAtom],
});

export const sussMsgState = atom({
    key: 'sussMsgState', 
    default: '', 
    effects_UNSTABLE: [persistAtom],
});

export const databaseListState = atom({
    key: 'databaseListState', 
    default: [], 
    effects_UNSTABLE: [persistAtom],
});

export const databaseState = atom({
    key: 'databaseState', 
    default: {}, 
    effects_UNSTABLE: [persistAtom],
});

export const tableListState = atom({
    key: 'tableListState', 
    default: [], 
    effects_UNSTABLE: [persistAtom],
});

export const tableNameState = atom({
    key: 'tableNameState', 
    default: '', 
    effects_UNSTABLE: [persistAtom],
});

export const ddlCodeState = atom({
    key: 'ddlCodeState', 
    default: '', 
    effects_UNSTABLE: [persistAtom],
});

export const tsCodeState = atom({
    key: 'tsCodeState', 
    default: '', 
    effects_UNSTABLE: [persistAtom],
});

